<template>
  <div>
    <!-- Waiting List Table -->
    <div class="bg-black-0 rounded shadow-sm mb-5 p-3">
      <h2>Waiting List Registrasi Pendonor</h2>
      <p class="m-0 w-25">
        Semua: ({{ waitingListTable.total }})
      </p>

      <b-row class="my-3 align-items-end">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group label="Pilih Lokasi MU : ">
            <registered-m-u-lists
              v-model="selectedMu"
              @input="handleInputMU"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-input-group
            class="search-input p-0"
            style="height: 40px"
          >
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
                style="height: 100%"
                @click="fetchWaitingListPendonor"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="waitingListTable.name"
              type="search"
              placeholder="Search Waiting List"
              class="border-left-0"
              style="height: 100%"
              @input="debouncedSearchWaitingList"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-table
        responsive
        show-empty
        :items="waitingListTable.items"
        :fields="waitingListTable.fields"
        :busy="waitingListTable.isBusy"
      >
        <template #cell(status)="row">
          <b-badge variant="warning">
            Proses
          </b-badge>
        </template>
        <template #empty>
          <p class="hp-p1-body text-center">Tidak Ada Data</p>
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            class="mx-1"
            variant="success"
            :to="'/dashboards/donation/' + row.item.id"
          >
            <b-icon
              icon="menu-up"
              class="mr-2"
            />
            Detail
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-end">
        <b-pagination
          v-model="waitingListTable.currentPage"
          :total-rows="waitingListTable.total"
          :per-page="waitingListTable.perPage"
          aria-controls="my-table"
          @change="fetchWaitingListPendonor"
        />
      </div>
    </div>

    <!-- All Data Table -->
    <b-row class="bg-black-0 rounded mx-0 shadow-sm">
      <b-col
        cols="12"
        class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
      >
        <h2>Daftar Registrasi Pendonor</h2>
        <div class="d-flex justify-content-between align-items-end">
          <p class="m-0 w-25">
            Semua: ({{ allDataTable.total }})
          </p>
          <b-input-group class="search-input">
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
                @click="fetchAllPendonor"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="allDataTable.name"
              type="search"
              placeholder="Search All Data"
              class="border-left-0"
              @input="debouncedSearchAllData"
            />
          </b-input-group>
        </div>
      </b-col>
      <b-table
        class="mx-3"
        responsive
        show-empty
        :items="allDataTable.items"
        :fields="allDataTable.fields"
        :busy="allDataTable.isBusy"
      >
        <template #empty>
          <p class="hp-p1-body text-center">Tidak Ada Data</p>
        </template>
        <template #cell(status)="row">
          <b-badge :variant="getStatusVariant(row.item.status)">
            {{
              row.item.status === "Done"
                ? "Selesai"
                : row.item.status === "Approved"
                  ? "Diterima"
                  : "Ditolak"
            }}
          </b-badge>
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            class="mx-1"
            variant="success"
            :to="'/dashboards/donation/' + row.item.id"
          >
            <b-icon
              icon="menu-up"
              class="mr-2"
            />
            Detail
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="w-100 my-3 mx-5 d-flex justify-content-end">
        <b-pagination
          v-model="allDataTable.currentPage"
          :total-rows="allDataTable.total"
          :per-page="allDataTable.perPage"
          aria-controls="my-table"
          @change="fetchAllPendonor"
        />
      </div>
    </b-row>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BSpinner,
  BBadge,
  BPagination,
  BIcon,
} from 'bootstrap-vue'
import AktivasiPendonorService from '../../../../api/managependonor/managePendonorAPI'
import RegisteredMULists from '../../../../components/shared/RegisteredMULists.vue'

const waitingListTable = ref({
  name: '',
  items: [],
  fields: ['status', 'id', 'name', 'location', 'date', 'action'],
  currentPage: 1,
  perPage: 5,
  total: 0,
  isBusy: false,
})

const allDataTable = ref({
  name: '',
  items: [],
  fields: ['status', 'id', 'name', 'location', 'date', 'action'],
  currentPage: 1,
  perPage: 10,
  total: 0,
  isBusy: false,
})
const selectedMu = ref(null)

async function fetchAllPendonor() {
  allDataTable.value.isBusy = true
  try {
    const { data: response } = await AktivasiPendonorService.getAll({
      page: allDataTable.value.currentPage,
      per_page: allDataTable.value.perPage,
      search: allDataTable.value.name,
    })
    allDataTable.value.items = response.data.data
    allDataTable.value.total = response.data.total
  } catch (error) {
    console.error(error)
  } finally {
    allDataTable.value.isBusy = false
  }
}

async function fetchWaitingListPendonor() {
  waitingListTable.value.isBusy = true
  try {
    const params = {
      page: waitingListTable.value.currentPage,
      per_page: waitingListTable.value.perPage,
      search: waitingListTable.value.name,
    }

    if (selectedMu.value?.type === 'hospital' && selectedMu.value?.hospital_id) {
      params.hospital_id = selectedMu.value.hospital_id
    } else if (selectedMu.value?.type === 'blood_van' && selectedMu.value?.id) {
      params.blood_van_id = selectedMu.value.id
    }

    const { data: response } = await AktivasiPendonorService.getWaitingList(params)
    waitingListTable.value.items = response.data.data
    waitingListTable.value.total = response.data.total
  } catch (error) {
    console.error(error)
  } finally {
    waitingListTable.value.isBusy = false
  }
}

function getStatusVariant(status) {
  switch (status.toLowerCase()) {
    case 'done':
      return 'primary'
    case 'approved':
      return 'success'
    case 'rejected':
      return 'danger'
    default:
      return 'warning'
  }
}

const debouncedSearchWaitingList = debounce(
  () => fetchWaitingListPendonor(),
  300,
)
const debouncedSearchAllData = debounce(() => fetchAllPendonor(), 300)

function debounce(fn, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), delay)
  }
}

function handleInputMU(selectedValue) {
  console.log('Selected value:', selectedValue)
  selectedMu.value = selectedValue
  console.log('selectedMu value (id):', selectedMu.value)

  fetchWaitingListPendonor()
}

onMounted(() => {
  fetchWaitingListPendonor()
  fetchAllPendonor()
})
</script>
